import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { orange } from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { ChevronIcon } from 'shared/Icons/IconRegistry';
import { useIsRoleAdmin, useIsRoleUser, useIsRoleViewer } from 'shared/utils/authRoles';
import { Tenant } from 'types/models/TenantTypes';
import AppContext from '../../../@crema/utility/AppContext';
import { useAuthUser } from '../../../@crema/utility/AppHooks';
import { onJWTAuthSignout } from '../../../redux/actions';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { AuthUser } from '../../../types/models/AuthUser';
import { Fonts, ThemeMode } from '../../constants/AppEnums';

const useStyles = makeStyles(theme => {
  return {
    crUserInfo: {
      backgroundColor: 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      marginTop: '8px',
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props: { themeMode: ThemeMode }) =>
        props.themeMode === ThemeMode.LIGHT ? '#313541' : 'white',
    },
    tenantName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props: { themeMode: ThemeMode }) =>
        props.themeMode === ThemeMode.LIGHT ? '#313541' : 'white',
    },
    designation: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
    version: {
      fontSize: 10,
      marginTop: 5,
      color: theme.palette.text.secondary,
      textAlign: 'right'
    },
  };
});

const UserInfo: React.FC<{}> = () => {
  const { themeMode } = useContext<AppContextPropsType>(AppContext);
  const dispatch = useDispatch();
  const user: AuthUser | null = useAuthUser();
  const isAdmin = useIsRoleAdmin();
  const isUser = useIsRoleUser();
  const isViewer = useIsRoleViewer();

  // Get the title of the user, with Admin being the highest priority, then User, then Viewer
  var title = ""
  if (isAdmin) title = "Admin"
  else if (isUser) title = "User"
  else if (isViewer) title = "Viewer"


  const [anchorEl, setAnchorEl] = React.useState(null);

  // Redux store
  const { activeTenant }: { activeTenant: Tenant | null } = useSelector<AppState, AppState['tenant']
  >(({ tenant }) => tenant);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const classes = useStyles({ themeMode });

  return (
    <Box
      px={{ xs: 4, xl: 7 }}
      className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display='flex' alignItems='flex-start'>
        {user && user.photoURL ? (
          <Avatar className={classes.profilePic} src={user.photoURL} />
        ) : (
            <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
          )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box mb={0} className={clsx(classes.userName)}>
              {user && (user.displayName ? user.displayName : 'Admin User ')}
            </Box>
            <Box
              ml={3}
              className={classes.pointer}
              color={themeMode === 'light' ? '#313541' : 'white'}>
              <ChevronIcon onClick={handleClick} />
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {/* <MenuItem>My account</MenuItem> */}
                <MenuItem onClick={() => dispatch(onJWTAuthSignout())}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box className={classes.designation}>{title}</Box>
          <Box className={classes.tenantName}>{activeTenant ? (activeTenant.name ? activeTenant.name : activeTenant.code) : "No tenant"}</Box>
          <Box className={classes.version}>v. 0.9.48β</Box>
        </Box>
      </Box>

    </Box>
  );
};

export default UserInfo;
